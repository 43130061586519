@import 'variables.scss';
@import 'mixin.scss';
@import 'common.scss';
@import 'typo.scss';
@import 'cookieconsent.scss';

@import 'transition.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'popup.scss';

@import 'home.scss';
@import 'about.scss';
@import 'tech.scss';
@import 'news.scss';
@import 'privacy.scss';

.tech-hero {
    padding-top: 26.4rem;
    @include md {
        padding-top: 16.4rem;
    }
    @include sm {
        padding-top: 13.6rem;
    }
    &__head {
        grid-column: 2/10;
        display: flex;
        flex-direction: column;
        max-width: 79.4rem;
        @include md {
            grid-column: 1/7;
        }
        @include sm {
            grid-column: span 2;
            margin-bottom: 4rem;
        }
    }
    &__label {
        color: rgba($cl-desc, 1);
        margin-bottom: 2rem;
        @include md {
            margin-bottom: 1.2rem;
        }
    }
    &__title {
        color: rgba($cl-txt, 1);
        @include md {
            font-size: 6rem;
        }
        @include sm {
            font-size: 3.6rem;
            line-height: 1.2em;
        }
    }
    &__sub {
        grid-column: 13/17;
        align-self: end;
        color: rgba($cl-body, 1);
        @include md {
            grid-column: 8/13;
            font-size: 1.8rem;
        }
        @include sm {
            grid-column: span 2;
        }
    }
    .p-line {
        grid-column: 2/17;
        margin-top: 8rem;
        width: 100%;
        height: 1px;
        background-color: rgba($cl-txt, .1);
        @include md {
            grid-column: 1/13;
            margin-top: 8.5rem;
        }
        @include sm {
            grid-column: span 2;
            margin-top: 4rem;
        }
    }
}
.tech-vid {
    margin-bottom: 6rem;
    @include md {
        padding-bottom: 4rem;
    }
    &__label {
        margin-top: 4.2rem;
        grid-column: 2/6;
        grid-row: 1/2;
        display: flex;
        flex-wrap: nowrap;
        align-self: start;
        overflow: hidden;
        &-item {
            white-space: nowrap;
            .txt-hl {
                width: 2.2rem;
                text-align: center;
                display: inline-block;
                padding: 0 .4rem;
                border-radius: 50%;
                font-family: initial;
            }
            &.anim {
                animation: rtl 14s infinite linear;
            }
        }
        @include md {
            margin-top: 3rem;
            grid-column: 1/4;
        }
        @include sm {
            grid-column: span 2;
            margin-top: 2rem;
        }
    }
    &__holder {
        //grid-column: 1/17;
        grid-column: 2/16;
        margin-top: 4.2rem;
        grid-row: 1/2;
        opacity: 0;
        pointer-events: none;
        @include md {
            grid-area: 1 / 1 / 2 / 13;
            margin-top: 3rem;
            margin-top: auto;
        }
        @include sm {
            display: none;
        }
    }
    &__main {
        margin-top: 4.2rem;
        // grid-column: 1/17;
        grid-column: 2/16;
        grid-row: 1/2;
        display: flex;
        justify-content: end;
        align-items: start;
        @include md {
            grid-area: 1 / 1 / 2 / 13;
            margin-top: 3rem;
        }
        @include sm {
            margin-top: 2rem;
            grid-area: span 1 / span 2;
        }
        &-inner {

            //width: 24%;
            width: calc(28.5714% - 1.42857rem);
            margin-right: calc(-7.14286% - .142857rem);
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                pointer-events: none;
                z-index: 2;
            }
            &.clear-thumb {
                .tech-vid__main-thumbnail {
                    opacity: 0;
                    @include transition(0.4s ease-out);
                }
            }
            @include md {
                width: 40.25%;
                margin-right: 0;
            }
            @include sm {
                width: 100%;
            }
        }
        &-vid {
            object-fit: cover;
            position: absolute;
            inset: 0;
            z-index: 0;
        }
        &-thumbnail {
            z-index: 1;
            pointer-events: none;
        }
    }
    &__play-btn {
        position: absolute;
        z-index: 3;
        pointer-events: auto;
        @include sm-up {
            transition: opacity $dur-default ease;
        }
        @include size(12rem);
        @include sm {
            @include size(6.4rem);
        }
        &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            @include size(100%);
            background-color: rgba($cl-orange, 1);
            color: rgba($cl-white, 1);
            backdrop-filter: blur(3px);
            transition: all $dur-default ease;
            svg {
                height: 3.6rem;
                width: auto;
                position: absolute;
                @include transition(transform $dur-default ease);
                @include sm {
                    @include size(30%);
                }
            }
            .ic-pause {
                opacity: 0;
            }
        }
        &:hover {
            .tech-vid__play-btn-inner {
                //background-color: rgba($cl-white, .2);
                transform: scale(.9);
                svg {
                    transform: scale(1.2);
                }
            }
        }
        &.playing {
            pointer-events: none;
            @include sm {
                opacity: 0;
            }
            .ic-pause {
                opacity: 1;
            }
            .ic-play {
                opacity: 0;
            }
        }
    }

    &.end-state {
        .tech-vid {
            &__main {
                &-inner {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}
.tech-demo {
    background-color: rgba($cl-dark-bg, 1);
    &__wrap {
        position: relative;
        display: grid;
    }
    &__popup {
        display: none;
        pointer-events: none;
        z-index: 2;
        @include sm {
            display: block;
            position: sticky;
            grid-column: 1/3;
            grid-row: 1/2;
            top: 0;
            width: 100%;
            height: 100svh;
        }
        // &-toggle {
        //     position: absolute;
        //     right: 0;
        //     bottom: 3.4rem;
        //     display: flex;
        //     align-items: center;
        //     column-gap: .4rem;
        //     z-index: 2;
        //     color: rgba($cl-white, .7);
        //     pointer-events: auto;
        // }
        &-inner {
            display: grid;
            position: absolute;
            bottom: 0;
            align-items: end;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 3;
        }
        &-item {
            grid-column: 1/2;
            grid-row: 1/2;
            background-color: rgba($cl-white, 1);
            padding: 2.4rem;
            margin-bottom: 2rem;
            flex-direction: column;
            pointer-events: none;
            opacity: 0;
            @include transform(translateY(20%));
            @include transition(all $dur-default ease);
            &.active {
                display: flex;
                opacity: 1;
                pointer-events: auto;
                @include transform(translateY(0%));
                .tech-demo__popup-close {
                    pointer-events: auto;
                }
            }
            &-title {
                margin-bottom: 1.6rem;
                font-size: 2rem;
                line-height: 1.4em;
            }
            &-richtext {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                &-p {
                    font-size: 1.4rem;
                    line-height: 1.5em;
                }
            }
        }
        &-close {
            display: flex;
            align-items: center;
            column-gap: .4rem;
            z-index: 2;
            color: rgba($cl-body, 1);
            pointer-events: none;
            margin-left: auto;
            margin-top: 1.6rem;
        }
    }
    &__main {
        grid-column: 1/2;
        grid-row: 1/2;
        z-index: 2;
        &-outer {
            height: 500vh;
            position: relative;
            @include sm {
                height: 400vh;
            }
        }
        &-inner {
            position: sticky;
            top: 0;
            height: 100vh;
            overflow: hidden;
            grid-column: 12/16;
            mask-image: linear-gradient(rgba($cl-dark-bg, 0) 0%, rgba($cl-dark-bg, 1) 25vh, rgba($cl-dark-bg, 1) calc(100% - 25vh), rgba($cl-dark-bg, 0) 100%);
            z-index: 1;
            @include md {
                grid-column: 8/13;
            }
            @include sm {
                grid-column: 1/3;
                grid-row: 1/2;
                display: flex;
                flex-direction: column;
                width: auto;
                height: 100svh;
                padding-bottom: 2.4rem;
                mask-image: none;
                justify-content: end;
                pointer-events: none;
                align-items: start;
            }
        }
        &-item {
            height: auto;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include sm {
                height: auto;
                min-height: auto;
                pointer-events: auto;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                &.active {
                    .tech-demo__main-item-title {
                        color: rgba($cl-white, 1);
                    }
                    .tech-demo__main-item-toggle {
                        pointer-events: auto;
                        opacity: 1;
                    }
                }
            }
            &-title {
                color: rgba($cl-white, 1);
                margin-bottom: 3.2rem;
                @include md {
                    font-size: 4rem;
                }
                @include sm {
                    font-size: 2rem;
                    line-height: 1.4em;
                    margin-bottom: 1rem;
                    margin-top: 1rem;
                    margin-right: 2.4rem;
                    color: rgba($cl-white, .2);
                    @include transition(color $dur-default ease)
                }
            }
            &-richtext {
                color: rgba($cl-white, .7);
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                p {
                    @include md {
                        font-size: 1.8rem;
                    }
                }
                @include sm {
                    display: none;
                }
            }
            &-toggle {
                display: none;
                @include sm {
                    display: flex;
                    align-items: center;
                    column-gap: .4rem;
                    opacity: 0;
                    pointer-events: none;
                    z-index: 2;
                    color: rgba($cl-white, .7);
                    flex-shrink: 0;
                    @include transition(opacity $dur-default ease);
                    .txt-14 {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    &__canvas {
        grid-column: 1/2;
        grid-row: 1/2;
        z-index: 1;

        pointer-events: none;
        position: sticky;
        top: 0;
        height: 100vh;
        .container {
            height: 100%;
        }
        @include sm {
            height: 100svh;
        }
        &-inner {
            margin-left: -6rem;
            margin-right: -6rem;
            width: calc(100% + 12rem);
            height: 100%;
            @include md {
                margin: 0 -4rem;
                width: calc(100% + 8rem);
            }
            @include xs {
                margin: 0 -2rem;
                width: calc(100% + 4rem);
            }
        }
    }

}
.tech-map {
    padding-top: 14.8rem;
    position: relative;
    z-index: 2;
    @include md {
        padding-top: 10rem;
    }
    &__main {
        grid-column: 2/16;
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        column-gap: 2rem;
        @include md {
            grid-column: 1/13;
            grid-template-columns: repeat(2, 1fr);
        }
        @include sm {
            grid-column: span 2;
            display: flex;
            flex-direction: column-reverse;
        }
    }
    &__map {
        grid-column: 1/9;
        background-color: rgba(#141414, 1);
        position: relative;
        z-index: 1;
        @include md {
            grid-column: span 1;
        }
        @include sm {
            margin: 0 -4rem;
        }
        @include xs {
            margin: 0 -2rem;
        }
        &-inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
            @include sm {
                padding-top: 160%;
            }
        }

        &:hover {
            .leaflet-control-container {
                .leaflet-top.leaflet-left {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        //Leaflet custom
        path.leaflet-interactive {
            stroke: rgba($cl-orange, 1);
        }
        .leaflet-container {
            background-color: rgba(#141414, 1);
        }

        .leaflet-control-container {
            .leaflet-top.leaflet-left {
                opacity: 0;
                transform: translateX(100%);
                transition: all $dur-default ease;
                top: 0;
                right: 0;
                left: auto;
                @include md {
                    opacity: 1;
                    transform: none;
                }
                @include sm {
                    top: 50%;
                    @include transform(translateY(-50%));
                }
                .leaflet-control-zoom.leaflet-bar.leaflet-control {
                    margin-left: 0;
                    margin-right: 2rem;
                    margin-top: 2rem;
                    border-radius: 0rem;
                    border: none;
                    overflow: hidden;
                    .leaflet-control-zoom-in, .leaflet-control-zoom-out {
                        @include size(3.2rem);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        border-radius: 0 !important;
                        span {
                            position: absolute;
                            font-size: 2.4rem;
                            line-height: 1em;
                            @include sm {
                                font-size: 2rem;
                            }
                        }
                    }
                    @include sm {
                        margin-top: 0;
                    }
                }
            }
            .leaflet-bottom.leaflet-right {
                display: none;
            }
        }
        .leaflet-popup-content {
            min-width: 23rem;
            padding: 1.4rem 2rem;
            margin: 0;
            &-wrapper {
                border-radius: 0;
                background: rgba($cl-white, 1);
                color: rgba($cl-txt, 1);
            }
            &-inner {
                display: flex;
                flex-direction: column;
                row-gap: .6rem;
            }
        }
        .leaflet-popup-tip {
            background: rgba($cl-white, 1);
        }
    }
    &__head {
        grid-column: 9/15;
        display: flex;
        flex-direction: column;
        padding: 9rem 5rem;
        background-color: rgba($cl-bg-grey, 1);
        @include md {
            grid-column: span 1;
            padding: 6.75rem 3.25rem;
            z-index: 2;
            position: relative;
        }
        @include sm {
            margin-bottom: -10rem;
            padding: 3.8rem 2.5rem;
        }
    }
    &__title {
        color: rgba($cl-txt, 1);
        margin-bottom: 4rem;
        @include md {
            font-size: 4rem;
            margin-bottom: 2.8rem;
            br {
                display: none;
            }
        }
        @include sm {
            font-size: 3.2rem;
        }
    }
    &__sub {
        color: rgba($cl-body, 1);
        margin-bottom: 4rem;
        @include md {
            font-size: 1.8rem;
            margin-bottom: 2.8rem;
        }
        @include sm {
            font-size: 1.6rem;
        }
    }
    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-wrap {
            position: relative;
            display: flex;
            align-items: center;
            &:hover, &:focus {
                .input-field-line {
                    transform: scaleX(1);
                }
            }
            &:focus-within {
                .icon {
                    opacity: 1;
                }
            }
        }
        .input-hidden {
            display: none;
        }
        .input-field {
            color: rgba($cl-txt,1);
            padding-left: 0;
            padding-right: 0;
            border-bottom: solid 1px rgba($cl-txt, .1);
            &::placeholder {
                color: rgba($cl-body, .5);
            }
            @include md {
                padding: 2.4rem 0;
            }
        }
        .input-icon {
            color: rgba($cl-body, .5);
            position: absolute;
            right: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity $dur-default ease;
        }
        .input-drop {
            position: absolute;
            z-index: 2;
            top: calc(100% - 1px);
            left: 0;
            width: 100%;
            max-height: 27.4rem;
            background-color: rgba($cl-dark-bg, 1);
            overflow: hidden;
            overflow-y: auto;
            display: none;
            color: rgba($cl-desc, 1);

            &-inner {
                display: flex;
                flex-direction: column;
                .port-item {
                    width: 100%;
                    display: block;
                    padding: 1.6rem 2.4rem;
                    transition: background-color $dur-default ease, opacity $dur-default ease;
                    background-color: rgba($cl-white, 0);
                    opacity: 1;
                    &-id {
                        display: none;
                    }
                    &.hidden-dup {
                        display: none !important;
                    }
                    &.hidden-srch {
                        opacity: 0;
                    }
                    &:hover {
                        background-color: rgba($cl-white, .05);
                    }
                }
            }
            .port-item-empty-txt {
                width: 100%;
                display: none;
                padding: 1.6rem 2.4rem;
            }
        }
        .input-field-line {
            transform-origin: 0;
            background-color: rgba($cl-txt, .4);
            width: 100%;
            height: 1px;
            transition: transform .4s;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
        }
        .tech-map__submit {
            width: 100%;
            margin-top: 3rem;
            position: relative;
            &-icon {
                width: 0rem;
                opacity: 0;
                transition: all $dur-default ease;
                .icon {
                    margin-right: 1rem;
                    animation: animRot 1s linear infinite;
                }
            }
            &.loading {
                .tech-map__submit-icon {
                    opacity: 1;
                    width: 3rem;
                }
            }
            @keyframes animRot {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
.tech-control {
    padding-top: 21rem;
    background-color: rgba($cl-white, 1);
    position: relative;
    z-index: 1;
    @include md {
        padding-top: 12rem;
    }
    @include sm {
        padding: 10rem 0 12rem;
    }
    &__head {
        grid-column: 2/7;
        display: grid;
        flex-direction: column;
        align-items: start;
        align-self: start;
        @include md {
            grid-area: 1/1/2/7;
        }
        @include sm {
            grid-column: 1/3;
            margin-bottom: -2rem;
        }
    }
    &__title {
        max-width: 43rem;
        margin-bottom: 4rem;
        @include md {
            font-size: 4rem;
        }
        @include sm {
            font-size: 3.2rem;
            margin-bottom: 2.8rem;
        }
    }
    &__richtext {
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        max-width: 49rem;
        &-p {
            color: rgba($cl-body, 1);
        }
    }
    &__img {
        grid-column: 7/17;
        align-self: end;
        display: flex;
        flex-direction: column;
        justify-content: end;
        @include md {
            display: block;
            grid-area: 1/5/2/13;
            margin-right: -4rem;
        }
        @include sm {
            grid-column: 1/3;
            grid-row: 2/3;
            margin: 0;
        }
    }
}


.abt-hero {
    padding-top: 28.2rem;
    &__label {
        grid-column: 3/5;
        align-self: start;
        color: rgba($cl-desc, 1);
        margin-top: 1.8rem;
    }
    &__title {
        grid-column: 5/16;
        max-width: 102rem;
        padding-bottom: 15.7rem;
    }
    &__bg-wrap {
        background-color: rgba($cl-orange, 1);
        height: 62.8rem;
    }
}
.abt-info {
    position: relative;
    z-index: 1;
    background-color: rgba($cl-white, 1);
    &__quote {
        grid-column: 1/7;
        margin-right: -2rem;
        background-color: rgba($cl-sub, 1);
        padding: 9rem 4.8rem 7.3rem 2.3rem;
        display: flex;
        align-items: start;
        column-gap: 3.1rem;
        margin-top: -33.8rem;
        align-self: start;
        &-icon {
            color: rgba($cl-white, 1);
        }
        &-main {
            display: flex;
            flex-direction: column;
            align-items: start;
        }
        &-txt {
            color: rgba($cl-white, .7);
            max-width: 45.5rem;
            margin-bottom: 4.5rem;
            line-height: 1.3em;
            font-weight: 400;
        }
        &-author {
            color: rgba($cl-white, 1);
        }
        &-job {
            color: rgba($cl-dark-sub, 1);
        }
    }
    &__richtext {
        grid-column: 7/12;
        grid-row: 2/4;
        align-self: start;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        position: relative;
        margin-bottom: 41.5rem;
        &-img {
            width: 100%;
        }
        &-h3 {
            margin-top: 4rem;
        }
        &-p {
            margin-top: 2.4rem;
            color: rgba($cl-body, 1);
        }
        &-decor {
            width: 10.2rem;
            position: absolute;
            bottom: 100%;
            left: 100%;
            background-color: #EAEEF0;
            pointer-events: none;
            &::after {
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                display: block;
            }
        }
    }
    &__add {
        grid-column: 2/4;
        grid-row: 2/3;
        margin-top: 10rem;
        margin-bottom: 24.6rem;
        align-self: start;
        display: flex;
        flex-direction: column;
        row-gap: 10rem;
        &-member {
            display: grid;
            &-inner {
                grid-column: 1/2;
                grid-row: 1/2;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: start;
                padding: 2rem;
                background-color: #EAEEF0;
            }
            &-amount {
                color: rgba($cl-sub,1);
                margin-bottom: 2rem;
            }
            &-label {
                color: rgba($cl-sub,1);
            }
            &::after {
                content: '';
                grid-column: 1/2;
                grid-row: 1/2;
                display: block;
                width: 100%;
                height: 0;
                padding-top: 100%;
                pointer-events: none;
            }
        }
    }
    &__swiper {
        grid-column: 2/6;
        grid-row: 3/4;
        margin-bottom: 20rem;
        position: relative;
        align-self: start;
        width: 100%;
        .swiper {
            width: 100%;
            aspect-ratio: 386 / 225;
            &-wrapper {
            }
            &-slide {
                display: block;
                video {}
            }
            &-nav {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                pointer-events: none;
                position: absolute;
                right: 1.2rem;
                top: 1.2rem;
                z-index: 5;
                opacity: 0;
                @include transition(all $dur-default ease);
                @include transform(translateX(100%));
                &-btn {
                    pointer-events: auto;
                    @include size(3.2rem);
                    background-color: rgba($cl-white, 1);
                    color: rgba($cl-dark-bg, 1);
                    opacity: .8;
                    @include transition(opacity $dur-default ease);
                    &-left {
                        border-bottom: solid 1px #CFD3D4;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
            }
            &:hover {
                .swiper-nav {
                    opacity: 1;
                    @include transform(translateX(0%));
                }
            }
        }
    }
    &__vis {
        grid-column: 14/17;
        grid-row: 2/4;
        align-self: end;
        justify-self: start;
        max-width: 28.5rem;
        margin-bottom: 20rem;
        &-title {
            color: rgba($cl-txt, 1);
            margin-bottom: 1.6rem;
        }
        &-txt {
            color: rgba($cl-body, 1);
        }
    }
}
.abt-mil {
    &-pin-container {
        position: relative;
        z-index: 2;
    }
    position: relative;
    z-index: 2;
    margin-top: -1px;
    margin-bottom: -1px;
    &__ship {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        &-img {
            margin: 0 auto;
            width: 100%;
            max-width: 14.2rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-behind {
            z-index: 1;
        }
        &-front {
            z-index: 3;
            overflow: hidden;
        }
    }
    &__wrap {
        height: calc(100vh + 2px);
        background-color: rgba($cl-dark-bg, 1);
        position: relative;
        z-index: 2;
        .container {
            height: 100%;
            overflow: hidden;
        }
    }
    &__head {
        grid-column: 2/7;
        margin-top: 4%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        align-self: center;
        max-width: 68.7rem;
    }
    &__label {
        color: rgba($cl-desc, 1);
        margin-bottom: 2rem;
    }
    &__title {
        color: rgba($cl-white, 1);
        //margin-bottom: 4rem;
        //max-width: 28.6rem;
    }
    &__progress {
        width: 1.6rem;
        height: 25.5rem;
        border-radius: 9.9rem;
        border: solid 1px rgba($cl-white, .1);
        position: absolute;
        left: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
        &-inner {
            width: 100%;
            height: calc(100% - 1rem - 2px);
            position: relative;
            margin-top: calc(.8rem - 1px);
            margin-bottom: calc(.8rem - 1px);
            display: flex;
            justify-content: center;
        }
        &-dot {
            position: absolute;
            top: 0%;
            margin-top: -.5rem;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: rgba($cl-orange, 1);
        }
    }
    &__main {
        grid-column: 11/16;
        height: 100vh;
        //padding-top: 100vh;
        position: relative;
        &::before, &::after {
            content: '';
            position: absolute;
            width: 100%;
            display: block;
            height: 25vh;
            z-index: 2;
            pointer-events: none;
        }
        &::before {
            top: -2px;
            background: linear-gradient(180deg, rgba($cl-dark-bg, 1) 0%, rgba($cl-dark-bg, .8) 45%, rgba($cl-dark-bg, 0) 100%)
        }
        &::after {
            bottom: -2px;
            background: linear-gradient(0deg, rgba($cl-dark-bg, 1) 0%, rgba($cl-dark-bg, .8) 45%, rgba($cl-dark-bg, 0) 100%)
        }
        &-inner {
            position: relative;
            z-index: 1;
            padding-top: 35vh;
            padding-bottom: 35vh;
            display: flex;
            flex-direction: column;
            justify-content: start;
            row-gap: 5rem;
            max-width: 48.5rem;
        }
        &-item {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding-top: 4rem;
            padding-bottom: 4rem;
            min-height: 50vh;
            &-label {
                color: rgba($cl-white, 1);
                margin-bottom: .4rem;
            }
            &-title {
                color: rgba($cl-white, 1);
                margin-bottom: 2rem;
            }
            &-richtext {
                display: flex;
                flex-direction: column;
                row-gap: 2rem;
                &-p {
                    color: rgba($cl-white, .7);
                }
            }
        }
    }
}
.abt-team {
    background-color: rgba($cl-bg, 1);
    position: relative;
    z-index: 3;
    padding-top: 15rem;
    &__title {
        grid-column: 2/10;
        max-width: 49.8rem;
    }
    &__richtext {
        grid-column: 12/16;
        margin-top: 3.1rem;
        display: flex;
        flex-direction: column;
        align-items: start;
        &-p {
            margin-bottom: 4rem;
            color: rgba($cl-body, 1);
        }
        &-link {
            &.hidden {
                visibility: hidden;
            }
        }
    }
    .p-line {
        grid-column: 2/16;
        width: 100%;
        height: 1px;
        margin-top: 8rem;
        background-color: rgba($cl-txt, .1);
    }
    &__main {
        grid-column: 1/17;
        margin-top: 8rem;
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        column-gap: 2rem;
        &-img {
            grid-column: 2/7;
            transform-origin: center;
            z-index: 2;
            pointer-events: none;
            position: relative;
            &-inner {
                display: grid;
                width: 48.9rem;
                position: absolute;
            }
            &-item {
                grid-column: 1/2;
                grid-row: 1/2;
                opacity: 0;
                transition: opacity 600ms ease;
                background-color: rgba($cl-white, 1);
                &.active {
                    opacity: 1;
                }
            }
            &-info {
                display: none;
            }
            &-close {
                display: none;
            }
        }

        &-list {
            grid-column: 9/16;
            display: flex;
            flex-direction: column;
            padding-bottom: 20rem;
            min-height: 112rem;
        }
        &-item {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            column-gap: 2rem;
            padding-top: 2.8rem;
            padding-bottom: 2.8rem;
            position: relative;
            align-items: center;
            &:hover {
                .abt-team__main-item-line-inner {
                    transform: scaleX(1);
                }
            }
            &-line {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba($cl-txt, .1);
                &-inner {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: .3rem;
                    background-color: rgba($cl-orange, 1);
                    transform-origin: left;
                    transform: scaleX(0);
                    transition: transform $dur-default ease;
                }
            }
            &-name {
                grid-column: 1/4;
                color: rgba($cl-txt, 1);
            }
            &-job {
                grid-column: 4/7;
                color: rgba($cl-txt, 1);
            }
            &-icon {
                justify-self: end;
                grid-column: 7/8;
                color: rgba($cl-disable, 1);
                transition: color $dur-default ease;
                &:hover {
                    color: rgba($cl-sub, 1);
                }
                &[href="#"] {
                    visibility: hidden;
                }
            }
        }
    }
}
.abt-job {
    padding-bottom: 32.4rem;
    padding-top: 20rem;
    &.hidden {
        display: none;
    }
    &__label {
        grid-column: 2/5;
        align-self: start;
        margin-top: 1.4rem;
        color: rgba($cl-desc, 1);
    }
    &__title {
        grid-column: 6/14;
        max-width: 79.5rem;
        color: rgba($cl-txt, 1);
    }
    &__main {
        grid-column: 2/16;
        margin-top: 12rem;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 2rem 2rem;
        &::before {
            content: '';
            grid-column: span 2;
            display: block;
            visibility: hidden;
        }
        &-item {
            grid-column: span 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 4.4rem;
            position: relative;
            background-color: #F5F5F5;
            height: 38.7rem;
            &-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2.4rem;
            }
            &-loca {
                color: rgba($cl-txt, 1);
            }
            &-type {
                color: rgba($cl-desc, 1);
                transition: opacity $dur-default ease;
            }
            &-soon {
                color: rgba($cl-note, 1);
                display: none;
            }
            &-title {
                margin-top: auto;
                color: rgba($cl-sub, 1);
                transition: color $dur-default ease;
            }
            &-icon {
                position: absolute;
                top: 4.4rem;
                right: 4.4rem;
                color: rgba($cl-orange, 1);
                opacity: 0;
                transform: translate(-100%, 100%);
                transition: all $dur-default ease;
            }
            &:hover {
                .abt-job__main-item {
                    &-type {
                        opacity: 0;
                    }
                    &-title {
                        color: rgba($cl-orange, 1);
                    }
                    &-icon {
                        transform: translate(0, 0);
                        opacity: 1;
                    }
                }
            }
            &.disable {
                pointer-events: none;
                .abt-job__main-item {
                    &-loca {
                        color: rgba($cl-note, 1);
                    }
                    &-type {
                        color: rgba($cl-note, 1);
                        display: none;
                    }
                    &-soon {
                        display: block;
                    }
                    &-title {
                        color: rgba($cl-note, 1);
                    }
                }
            }
            &:nth-child(2) {
                grid-column: span 3;
            }
            &:nth-child(6n + 3) {
                grid-column: 3 span;
            }
            &:nth-child(6n + 5) {
                grid-column: 2 span;
            }
            &:nth-child(6n + 8) {
                grid-column: 3 span;
            }
        }
    }
}

@include md {
    .abt-hero {
        padding-top: 16.4rem;
        &__label {
            grid-column: 1/3;
        }
        &__title {
            grid-column: 4/13;
            max-width: none;
            padding-bottom: 12rem;
        }
        &__bg-wrap {
            height: 51rem;
        }
    }
    .abt-info {
        &__quote {
            padding: 5.2rem 4rem 4.8rem 3.2rem;
            column-gap: 2rem;
            margin-top: -23.2rem;
            margin-right: -2rem;
            &-icon {
                @include size(3.2rem, 3.2rem);
            }
            &-txt {
                max-width: none;
                font-size: 2.6rem;
                line-height: 1.4em;
                margin-bottom: 3.2rem;
            }
        }
        &__richtext {
            grid-column: 7/13;
            margin-bottom: auto;
            &-decor {
                @include size(8.4rem, 9.8rem);
                left: auto;
                right: -4rem;
                &::after {
                    display: none;
                }
            }
            &-h3 {
                margin-top: 3.2rem;
            }
            &-p {
                margin-top: 1.6rem;
            }
        }
        &__add {
            grid-column: 1/4;
            grid-row: 2/3;
            row-gap: 6rem;
            margin-bottom: 0;
            &-member {
                &-amount {
                    font-size: 3.6rem;
                    margin-bottom: 1.2rem;
                }
            }
        }
        &__swiper {
            grid-column: 1/6;
            grid-row: 3/5;
            margin-top: 10rem;
            margin-bottom: 25rem;
        }
        &__vis {
            grid-column: 7/13;
            grid-row: 4/5;
            margin-bottom: 22.6rem;
            margin-top: 4.4rem;
            &-title {
                margin-bottom: 1.2rem;
            }
        }
    }
    .abt-mil {
        &__ship {
            &-img {
                margin-right: auto;
                margin-left: 28vw;
            }
        }
        &__head {
            grid-column: 1/3;
            //margin-right: -2rem;
        }
        &__title {
            font-size: 3.2rem;
            //margin-bottom: 2.2rem;
        }
        &__label {
            margin-bottom: 1.2rem;
        }
        &__main {
            grid-column: 7/13;
            &-item {
                &-title {
                    font-size: 4rem;
                }
            }
        }
        &__progress {
            width: 1rem;
            left: 1.2rem;
        }
    }
    .abt-team {
        padding: 8rem 0;
        &.open-popup {
            z-index: 100;
        }
        &__title {
            grid-column: 1/5;
            margin-right: -2rem;
        }
        &__richtext {
            margin-top: 0;
            grid-column: 7/13;
            &-p {
                margin-bottom: 3.2rem;
            }
        }
        .p-line {
            grid-column: 1/13;
            margin-top: 4rem;
        }
        &__main {
            grid-column: 1/13;
            margin-top: 3.2rem;
            display: block;
            &-img {
                position: fixed;
                z-index: 3;
                inset: 0;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.94);
                opacity: 0;
                pointer-events: none;
                @include transition(0.3s ease);
                &-inner {
                    width: 50%;
                    margin: 0 auto;
                    position: absolute;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
                    display: grid;
                    grid-template-columns: 1fr;
                    padding: 6rem 2.4rem 3.2rem;
                    background-color: $cl-white;
                }
                &-close {
                    display: flex;
                    color: rgba($cl-body, 1);
                    position: absolute;
                    z-index: 3;
                    top: 2rem;
                    right: 2rem;
                    padding: 2rem;
                    margin: -2rem;
                    width: 6rem;
                    height: 6rem;
                }
                &-item {
                    position: relative;
                    z-index: 2;
                    grid-area: 1/1/2/2;
                    opacity: 1;
                    @include size(auto);
                    margin: 0 -2.4rem;
                    display: flex;
                    aspect-ratio: 0.9;
                    position: relative;
                    overflow: hidden;
                    opacity: 0;
                    img {
                        object-fit: cover;
                    }
                    &.selected {
                        opacity: 1;
                    }
                }
                &-info {
                    margin-top: 2rem;
                    display: flex;
                    justify-content: space-between;
                }
                &-icon {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $cl-orange;
                    aspect-ratio: 1;
                    &[href="#"] {
                        visibility: hidden;
                    }
                }
                &.active {
                    opacity: 1;
                    pointer-events: auto;
                    @include transition(0.3s ease);
                }
            }
            &-list {
                padding-bottom: 2rem;
                min-height: auto;
            }
            &-item {
                padding: 2.4rem 0;
                grid-template-columns: 39.77fr 57.42fr 2.563fr;
                &-name,
                &-job,
                &-icon {
                    grid-column: span 1;
                }
                &-icon {
                    @include size(1.9rem, 1.9rem)
                }
            }
        }
    }
    .abt-job {
        padding: 10rem 0 23rem;
        &__label {
            grid-column: 1/3;
        }
        &__title {
            grid-column: 4/10;
        }
        &__main {
            grid-column: 1/13;
            margin-top: 6rem;
            grid-template-columns: repeat(12, 1fr);
            &::before {
                content: '';
                grid-column: span 3;
                display: block;
                visibility: hidden;
            }
            &-item {
                grid-column: span 4;
                padding: 3.2rem;
                height: 31.17vw;
                &-title {
                    font-size: 2.2rem;
                }
                &:nth-child(2) {
                    grid-column: span 5;
                }
                &:nth-child(6n + 3),
                &:nth-child(6n + 5),
                &:nth-child(6n + 8) {
                    grid-column: 4 span;
                }
            }
        }
    }
}

@include sm {
    .abt-hero {
        padding-top: 13.6rem;
        &__label {
            grid-column: 1/3;
            margin-top: 0;
            margin-bottom: 1.2rem;
        }
        &__title {
            grid-column: 1/3;
            padding-bottom: 7.8rem;
        }
        &__bg-wrap {
            height: 51rem;
        }
    }
    .abt-info {
        &__quote {
            padding: 6rem 3.2rem 4rem;
            grid-column: 1/3;
            margin-right: 0;
            margin-top: -38.3rem;
            flex-direction: column;
            &-txt {
                font-size: 2rem;
                margin: 2rem 0 2.4rem;
            }
        }
        &__richtext {
            grid-column: 1/3;
            grid-row: 3/4;
            &-decor {
                @include size(6rem);
                right: 0;
            }
            &-img {
                width: 82%;
                margin-right: 6rem;
            }
        }
        &__add {
            grid-column: 1/3;
            grid-row: 2/3;
            margin-right: 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 3.2rem 2rem;
            margin: 5rem 0;
            margin-bottom: 6rem;
            &-member {
                &-label {
                    font-size: 1.4rem;
                }
            }
        }
        &__swiper {
            grid-column: 1/3;
            grid-row: 5/6;
            margin-bottom: 22.8rem;
            .swiper {
                &-nav {
                    opacity: 1;
                    @include transform(translateX(0%));
                    &-btn {
                        opacity: 1;
                    }
                }
            }
        }
        &__vis {
            grid-column: 1/3;
            grid-row: 4/5;
            margin-bottom: 0;
            &-title {
                font-size: 1.8rem;
            }
        }
    }
    .abt-mil {
        &__ship {
            &-img {
                margin-left: 2rem;
                max-width: 8.238rem;
            }
        }
        &__head,
        &__main {
            grid-column: 2/3;
            margin-left: -4rem;
            &-inner {
                padding-top: 35svh;
                padding-bottom: 35svh;
            }
        }
        &__head {
            padding-top: 8rem;
            margin-top: -0.1rem;
            background-color: $cl-dark-bg;
            z-index: 2;
            grid-row: 1/2;
        }
        &__title {

        }
        &__main {
            grid-row: 1/3;
            z-index: 1;
            &-item {
                &-label {
                    font-size: 1.4rem;
                }
                &-title {
                    font-size: 3.2rem;
                }
                &-richtext-p {
                    font-size: 1.4rem;
                }
            }
            &::before {
                top: 21rem;
            }
            &::before,
            &::after {
                height: 12svh;
            }
        }
        &__progress {
            display: none;
        }
    }
    .abt-team {
        padding-top: 6rem;
        &__title {
            grid-column: 1/3;
            margin-right: 0;
            max-width: none;
            margin-bottom: 2.4rem;
        }
        &__richtext {
            grid-column: 1/3;
        }
        .p-line {
            grid-column: 1/3;
        }
        &__main {
            grid-column: 1/3;
            margin-top: 1rem;
            &-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 2rem 0;
                &-name {
                    margin-bottom: 0.4rem;
                }
                &-job {
                    font-size: 1.4rem;
                    line-height: 1.5em;
                }
                &-icon {
                    display: none;
                }
            }
            &-img {
                background-color: rgba(0,0,0,.95);
                height: 100svh;
                &-inner {
                    width: calc(100% - 8rem);
                    padding-top: 4.8rem;
                }
                &-close {
                    padding: 1.4rem;
                    width: 4.8rem;
                    height: 4.8rem;
                }
                &-name {
                    font-size: 1.8rem;
                    line-height: 1.6em;
                }
                &-job {
                    font-size: 1.4rem;
                    line-height: 1.5em;
                }
                &-icon {
                    .icon {
                        @include size(2.2rem);
                    }
                }
            }
        }
    }
    .abt-job {
        padding: 8rem 0 20.6rem;
        &__title {
            grid-column: 1/3;
            br {
                display: none;
            }
        }
        &__main {
            grid-column: 1/3;
            display: flex;
            flex-direction: column;
            margin-top: 4.8rem;
            &-item {
                height: 20rem;
                &-title {
                    font-size: 2rem;
                    margin-top: 0;
                }
                &-head {
                    margin-bottom: 0;
                }
                &-icon {
                    display: none;
                }
            }
        }
    }
}

@include xs {
    .abt-team {
        &__main {
            &-img {
                &-inner {
                    width: calc(100% - 4rem);
                }
            }
        }
    }
}
@mixin lg {
	@media screen and (max-width: $lg-min) {
		@content;
	}
}
@mixin md {
	@media screen and (max-width: $md-max) {
		@content;
	}
}
@mixin md-up {
	@media screen and (min-width: $md-min ) {
		@content;
	}
}
// tablet
@mixin sm {
	@media screen and (max-width: $sm-max) {
		@content;
	}
}
@mixin sm-up {
	@media screen and (min-width: $sm-min ) {
		@content;
	}
}
// mobile
@mixin xs {
	@media screen and (max-width: $xs-max) {
		@content;
	}
}

@mixin text($fs, $lh) {
	font-size: $fs;
	line-height: $lh;
}
@mixin size($w, $h: $w) {
	width: $w;
	height: $h;
}
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}
@mixin animation($str) {
	animation: #{$str};
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
}
@mixin transform($args) {
	transform: $args;
	-webkit-transform: $args;
	-moz-transform: $args;
	-ms-transform: $args;
	-o-transform: $args;
}

@mixin transform-origin($args) {
	transform-origin: $args;
	-webkit-transform-origin: $args;
	-moz-transform-origin: $args;
	-ms-transform-origin: $args;
	-o-transform-origin: $args;
}
@mixin transition($args) {
	transition: $args;
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
}
@mixin transition-delay($args) {
	transition-delay: $args;
	-webkit-transition-delay: $args;
	-moz-transition-delay: $args;
	-ms-transition-delay: $args;
	-o-transition-delay: $args;
}
@mixin custom-scroll($width: 0.7rem, $bgTrack: #ddd, $bgThumb: #1D1D1D) {
	&::-webkit-scrollbar {
		width: $width;
	}
	&::-webkit-scrollbar-track {
		background: $bgTrack;
	}
	&::-webkit-scrollbar-thumb {
		background: $bgThumb;
		border-radius: 2rem;
	}
}
.news-hero {
    &__logo {
        grid-column: 1/7;
        background-color: rgba($cl-orange, 1);
        position: relative;
        &::after {
            content: '';
            background-color: rgba($cl-orange, 1);
            position: absolute;
            top: 0;
            right: 0;
            width: 50vw;
            height: 100%;
            pointer-events: none;
            z-index: 1;
        }
        &-inner {
            position: sticky;
            width: 100%;
            height: 100vh;
            top: 0;
            z-index: 2;
            display: grid;
            pointer-events: none;
        }
        &-default, &-ex {
            grid-column: 1/2;
            grid-row: 1/2;
            display: flex;
            align-items: center;
            justify-content: center;
            &-item {
                opacity: 0;
                position: absolute;
                z-index: 1;
                transition: opacity $dur-default ease;
                &.active {
                    opacity: 1;
                    z-index: 2;
                }
            }
        }
    }

    &__content {
        grid-column: 9/16;
    }
    &__title {
        margin-top: 23.2rem;
        color: rgba($cl-txt, 1);
        margin-bottom: 16rem;
    }
    &__main {
        display: flex;
        flex-direction: column;
        &-item {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: start;
            padding-top: 4rem;
            padding-bottom: 4rem;
            border-bottom: solid 1px rgba($cl-txt, .1);
            &.hidden {
                display: none;
            }
            &-label {
                color: rgba($cl-note, 1);
                margin-bottom: 3.2rem;
                transition: color $dur-default ease;
            }
            &-title {
                color: rgba($cl-txt, 1);
                margin-bottom: 3.2rem;
                transition: color $dur-default ease;
            }
            &-info {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: end;
            }
            &-site {
                color: rgba($cl-note, 1);
            }
            &-year {
                opacity: .3;
                color: rgba($cl-note, 1);
            }
            &-icon {
                position: absolute;
                top: 4rem;
                right: 0;
                transform: translate(-100%, 100%);
                color: rgba($cl-orange, 1);
                opacity: 0;
                transition: all $dur-default ease;
            }
            &-line {
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0;
                bottom: 0;
                transform-origin: left;
                background-color: rgba($cl-orange, 1);
                transform: scaleX(0);
                transition: transform $dur-default ease;
            }
            &:first-child {
                border-top: solid 1px rgba($cl-txt, .1);
            }
            &:hover {
                .news-hero__main-item {
                    &-label {
                        color: rgba($cl-sub, 1);
                    }
                    &-title {
                        color: rgba($cl-orange, 1);
                    }
                    &-site {
                        color: rgba($cl-note, 1);
                    }
                    &-year {
                        opacity: .3;
                        color: rgba($cl-note, 1);
                    }
                    &-icon {
                        transform: translate(0%, 0%);
                        opacity: 1;
                    }
                    &-line {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
    &__load {
        margin-top: 10rem;
        margin-bottom: 11rem;
        display: inline-block;
        .btn {
            transition: opacity $dur-default ease;
            &.disable {
                pointer-events: none;
                opacity: 0;
                
            }
        }
    }
}
@include md {
    .news-hero  {
        &__logo {
            margin-left: -4rem;
            grid-column: 1/5;
            &-default, &-ex {
                width: 100%;
                max-width: 21.2rem;
                margin: 0 auto;
                &-item {
                    max-width: 21.2rem;
                    width: 100%;
                }
            }
        }
        &__content {
            grid-column: 7/13;
            margin-left: -2rem;
        }
        &__title {
            margin-top: 16.4rem;
            margin-bottom: 8rem;
            margin-right: -1rem;
        }
        &__main {
            &-item {
                padding-top: 3.2rem;
                padding-bottom: 3.2rem;
                &-label {
                    margin-bottom: 1.6rem;
                }
                &-title {
                    margin-bottom: 1.6rem;
                    margin-right: 2.4rem;
                }
                &-site {
                    color: rgba($cl-note, 1);
                }
                &-year {
                    font-size: 3.2rem;
                }
                &-icon {
                    top: 3.2rem;
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }
        }
        &__load {
            margin-top: 6rem;
            margin-bottom: 21rem;
            .btn {
                width: 17.3rem;
                padding-top: 1.2rem;
                padding-bottom: 1.2rem;
            }
        }
    }
}
@include sm {
    .news-hero  {
        &__logo {
            margin-top: 9.6rem;
            margin-left: 0;
            grid-column: 1/3;
            grid-row: 1/2;
            margin-left: -4rem;
            margin-right: -4rem;
            &::after {
                content: '';
                display: none
            }
            &-inner {
                position: relative;
                height: 16rem;
                top: auto;
            }
            &-default, &-ex {
                max-width: 12.8rem;
                &-item {
                    max-width: 12.8rem;
                }
            }
        }
        &__content {
            grid-column: 1/3;
            grid-row: 2/3;
            margin-left: 0;
        }
        &__title {
            margin-top: 4.4rem;
            margin-bottom: 4.4rem;
            margin-right: 0;
        }
        &__load {
            margin-top: 6rem;
            margin-bottom: 20.6rem;
            text-align: center;
            .btn {
                width: 16rem;
            }
        }
    }
}
@include sm {
    .news-hero  {
        &__logo {
            margin-left: -2rem;
            margin-right: -2rem;
        }
        &__load {
            width: 100%;
            .btn {
                width: 100%;
            }
        }
    }
}
:root {
    --h1-fs: 10rem;
    --h1-lh: 1.2em;

    --h2-fs: 8.4rem;
    --h2-lh: 1.2em;

    --h3-fs: 7.2rem;
    --h3-lh: 1.2em;

    --h4-fs: 6rem;
    --h4-lh: 1.2em;

    --h5-fs: 3.6em;
    --h5-lh: 1.2em;

    --h6-fs: 3.2rem;
    --h6-lh: 1.2em;

    --fs-12: 1.2rem;
    --lh-12: 1.6em;

    --fs-14: 1.4rem;
    --lh-14: 1.5em;

    --fs-16: 1.6rem;
    --lh-16: 1.6em;

    --fs-18: 1.8rem;
    --lh-18: 1.6em;

    --fs-20: 2rem;
    --lh-20: 1.3em;

    --fs-24: 2.4rem;
    --lh-24: 1.3em;

    --fs-28: 2.8em;
    --lh-28: 1.35em;

    --fs-36: 3.6rem;
    --lh-36: 1.2em;

    @media only screen and (max-width: 991px) {
        --h1-fs: 7.2rem;

        --h2-fs: 5.2rem;

        --h3-fs: 3.4rem;

        --h4-fs: 2.6rem;
        --h4-lh: 1.5em;

        --h6-fs: 2rem;
        --h6-lh: 1.4em;

        --fs-36: 2.4rem;
        --lh-36: 1.3em;

        --fs-24: 2rem;

        --fs-20: 1.8rem;
        --lh-20: 1.6em;

        --fs-18: 1.6rem;
    }
    @media only screen and (max-width: 767px) {
        --h1-fs: 6rem;

        --h2-fs: 3.6rem;

        --h3-fs: 3.2rem;

        --h4-fs: 2.4rem;

        --fs-24: 1.8rem;
        --fs-20: 1.6rem;


    }
    @media only screen and (max-width: 476px) {
        --h1-fs: 3.6rem;

        --h3-fs: 3.2rem;

    }

}

$txtSizes: 12, 14, 16, 18, 20, 24, 28, 36;

$xs-min: 477px;
$sm-min: 768px;
$md-min: 992px;
$lg-min: 1728px;

$xs-max: ($xs-min - 1); // Mobile
$sm-max: ($sm-min - 1); // Tablet
$md-max: ($md-min - 1); // Desktop
$lg-max: ($lg-min - 1); // Large Desktop
//Footer
.footer {
    background-color: rgba($cl-bg, 1);
    position: relative;
    z-index: 5;
    &__inner {
        position: relative;;
        z-index: 3;
        padding-bottom: 6rem;
        background-color: rgba($cl-bg, 1);
    }
    &__form {
        &-wrap {
            grid-column: 2/7;
            margin-top: -13.6rem;
            padding: 3.2rem 6rem 6rem;
            background-color: rgba($cl-dark-bg, 1);
        }
        &-icon {
            margin-bottom: 4rem;
        }
        &-title {
            color: rgba($cl-white, 1);
            margin-bottom: 1.2rem;
        }
        &-sub {
            color: rgba($cl-white, .5);
            margin-bottom: 2.4rem;
        }
        &-main {
            display: flex;
            flex-direction: column;
            row-gap: .8rem;
        }
        &-caption {
            margin-top: 1.2rem;
            color: rgba($cl-placeholder, 1);
        }
    }
    &__main {
        grid-column: 8/16;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
        border-bottom: 1px solid rgba($cl-txt, .1);
        margin-bottom: 0rem;
    }
    &__link {
        transition: color $dur-default ease;
        &:hover, &.active {
            color: rgba($cl-orange, 1);
        }
    }
    &__info {
        grid-template-columns: repeat(8, 1fr);
        &-mid {
            padding-top: 3.2rem;
            padding-bottom: 3.2rem;
        }
        &-addr {
            grid-column: 1/5;
            grid-template-columns: repeat(2, 1fr);
            &-label {
                margin-bottom: 1.6rem;
                font-weight: 700;
            }
            &-txt {
                color: rgba($cl-body, 1);
            }
        }
        &-contact {
            grid-column: 6/9;
            display: flex;
            flex-direction: column;
            row-gap: .8rem;
            &-label {
                color: rgba($cl-body, 1);
            }
            &-link {
                transition: color $dur-default ease;
                &:hover, &.active {
                    color: rgba($cl-orange, 1);
                }
            }
        }
        &-copy {
            grid-column: 1/5;
            color: rgba($cl-body, 1);
            margin-top: auto;
        }
        &-legal {
            grid-column: 6/9;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            &-link {
                color: rgba($cl-body, 1);
                transition: color $dur-default ease;
                &:hover, &.active {
                    color: rgba($cl-orange, 1);
                }
            }
        }
    }

    &__marquee {
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: block;
        overflow: hidden;
        color: rgba($cl-white, .16);
        background-color: rgba(0,0,0, 1);
        transition: color $dur-default ease;
        &:hover {
            color: rgba($cl-white, .2);
            // .footer__marquee-wrap {
            //     transform: translate(-4%);
            // }
            // .footer__marquee-item {
            //     animation-play-state: paused;
            // }
        }
        &-wrap {
            padding-top: 3.4rem;
            padding-bottom: 2.7rem;
            display: flex;
            align-items: center;
            justify-content: start;
            flex-wrap: nowrap;
            transform: translate(0);
            transition: transform 1.6s ease-out
        }
        &-item {
            padding-right: 3rem;
            white-space: nowrap;
            font-size: 18rem;
            line-height: 1.15em;
            animation-name: rtl;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-duration: 20s;
            animation-play-state: running;
            width: max-content;
            will-change: transform,width;
        }
    }
    &__cursor {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        bottom: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
        &-main {
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;
            pointer-events: auto;
            position: absolute;
            color: rgba($cl-white, 1);
            @include size(12rem);
            background-color: rgba($cl-orange, 1);
            top: 0;
            left: 0;
            @include transform(translate(-50%, -50%));
            opacity: 0;
            @include transition(opacity $dur-default ease);
        }
    }
    &__marquee:hover {
        .footer__cursor-main {
            opacity: 1;
        }
    }
}

@keyframes rtl {
    0% {
        transform: translateZ(0);
        visibility: visible;
    }
    100% {
        transform: translate3d(-100%,0,0);
    }
}
.input {
    &-wrap {
        width: 100%;
    }
    &-field {
        width: 100%;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        border: none;
        border-radius: none;
        background-color: transparent;
        padding: 2rem 2.4rem;
        border: 1px solid rgba($cl-white, .1);
        color: rgba($cl-white, 1);
        transition: all $dur-default ease;
        border-radius: 0;
        &::placeholder {
            color: rgba($cl-placeholder, 1);
        }
        &:not(:placeholder-shown) {
            border: 1px solid rgba($cl-white, .2);
        }
        &:hover, &:focus {
            border: 1px solid rgba($cl-white, .3);
        }
    }
    &-submit {
        width: 100%;
        border: none;
        border-radius: 0;
        &-icon {
            width: 0rem;
            opacity: 0;
            transition: all $dur-default ease;
            .icon {
                margin-right: 1rem;
            }
        }
        .input-submit-txt-tail {
            width: 0;
            overflow: hidden;
            transition: all $dur-default ease;
        }
        &.on-complete {
            .input-submit-icon {
                width: 3rem;
                opacity: 1;
            }
            .input-submit-txt-tail {
                width: 1.2rem;
            }
        }
    }
}

@include md {
    .footer {
        &__form {
            &-wrap {
                grid-column: 1/7;
                margin-top: -11rem;
                padding: 3.2rem 4rem 4rem;
            }
            &-icon {
                margin-bottom: 2.4rem;
                @include size(6rem, 6rem);
            }
            &-sub {
                margin-bottom: 3.2rem;
            }
            &-caption {
                margin-top: 1.6rem;
            }
        }
        &__main {
            grid-column: 1/13;
        }
        &__links {
            margin-bottom: 0;
        }
        &__link {
            font-size: 2.2rem;
        }
        &__info {
            padding-top: 4rem;
            grid-template-columns: repeat(12, 1fr);
            &-mid {
                padding-bottom: 0;
            }
            &-addr {
                grid-column: 1/7;
                &-label {
                    font-weight: 500;
                    margin-bottom: 1.2rem;
                }
            }
            &-contact {
                grid-column: 8/13;
                margin-left: 4rem;
                row-gap: 1.2rem;
                &-label {
                    margin-bottom: 0.2rem;
                }
                &-link {
                    font-size: 2rem;
                    font-weight: 500;
                }
            }
            &-copy {
                padding-top: 0;
            }
            &-legal {
                padding-top: 0;
                grid-column: 8/13;
                margin-left: 2.5rem;
            }
        }
        &__marquee {
            &-wrap {
                padding: 3.2rem 0;
            }
            &-item {
                font-size: 10rem;
                line-height: 1em;
            }
        }
        &__cursor {
            &-main {
                padding: 1.4rem;
                @include size(10rem);
                .arr-wrap-24 {
                    @include size(2rem);
                }
            }
        }
    }
}

@include sm {
    .footer {
        .container {
            padding-bottom: 3.2rem;
        }
        &__form {
            &-wrap {
                grid-column: 1/3;
                margin-top: -10.8rem;
                padding: 3rem 4rem 4rem;
            }
            &-icon {
                @include size(4.8rem, 4.8rem);
            }
            &-main {
                .input-field {
                    padding: 1.2rem 2.4rem;
                }
                .input-submit {
                    padding: 1.6rem 2rem;
                }
            }
        }
        &__main {
            grid-column: 1/3;
        }
        &__links {
            padding: 5rem 0 4rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 3.2rem 2rem;
        }
        &__link {
            font-size: 2rem;
            width: fit-content;
            &:nth-child(2) {
                grid-row: 2/3;
            }
            &:nth-child(3) {
                grid-row: 3/4;
            }
        }
        &__info {
            display: flex;
            flex-direction: column;
            &-addr {
                margin-bottom: 4rem;
                display: flex;
                flex-direction: column;
                row-gap: 2rem;
                &-label {
                    margin-bottom: .8rem;
                }
                &-txt br {
                    display: none;
                }
            }
            &-contact {
                margin-left: 0;
                flex-direction: column;
                column-gap: 2rem;
                &-label {
                    margin-bottom: .8rem;
                }
                &-link {
                    font-size: 1.6rem;
                }
            }
            &-copy {
                margin-bottom: 1.2rem;
            }
            &-legal {
                margin-left: 0;
                justify-content: left;
                column-gap: 3.2rem;
            }
        }
        &__marquee {
            &-wrap {
                
            }
            &-item {
                font-size: 6rem;
            }
        }
        &__cursor {
            display: none;
        }
    }
}
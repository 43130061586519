.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    &.active {
        pointer-events: auto;
        .popup__overlay {
            opacity: 1;
        }
        .popup__main {
            opacity: 1;
            transform: translateY(0);
            &-head {
                transition-delay: calc($dur-default / 2);
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            }
            &-submit {
                transition-delay: calc($dur-default / 2);
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            }
            &-form-inner {
                pointer-events: auto;
                &.hidden {
                    pointer-events: none;
                }
            }
            &-form-success {
                pointer-events: auto;
                &.hidden {
                    pointer-events: none;
                }
            }
        }
    }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($cl-txt, .95);
        opacity: 0;
        transition: opacity $dur-default ease;
    }
    &__main {
        position: relative;
        max-width: 108rem;
        margin-top: 12rem;
        margin-bottom: 6rem;
        width: 100%;
        padding: 6rem;
        padding-bottom: 0;
        background-color: rgba($cl-orange, 1);
        opacity: 0;
        transition: all $dur-default ease;
        transform: translateY(10%);
        &-form {
            display: grid;
            grid-template-columns: 4.1fr 5.9fr;
        }
        &-head {
            grid-column: 1/2;
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 6rem;
            margin-top: -12rem;
            background-color: rgba($cl-white, 1);
            clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
            transition: all $dur-default ease;
            transition-delay: 0s;
            overflow: hidden;
            &-title {
                margin-left: -1rem;
                margin-bottom: 4rem;
            }
            &-txt {
                color: rgba($cl-body, 1);
            }
        }
        &-contact {
            grid-column: 1/2;
            grid-row: 2/3;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: start;
            padding-bottom: 4.8rem;
            &-label {
                color: rgba($cl-white, 1);
            }
            &-link {
                color: rgba($cl-white, 1);
            }
        }
        &-form-inner{
            grid-column: 2/3;
            grid-row: 1/2;
            display: flex;
            flex-direction: column;
            transition: all $dur-default ease;
            opacity: 1;
            transform: none;
            &.hidden {
                opacity: 0;
                transform: translateY(3.2rem);
            }
            .input-wrap {
                margin-left: 6rem;
                position: relative;
                width: calc(100% - 6rem);
                &:hover, &:focus {
                    .input-field::placeholder {
                        color: rgba($cl-white, .7);
                    }
                    .input-field-line {
                        transform: scaleX(1);
                    }
                }
            }
            .input-field {
                border: none;
                border-bottom: solid 1px rgba($cl-white, .2);
                padding-left: 0;
                padding-right: 0;
                padding-top: 2.4rem;
                padding-bottom: 2.4rem;
                color: rgba($cl-white,1);
                &::placeholder {
                    color: rgba($cl-white, .5);
                    transition: color $dur-default ease;
                }

            }
            .textarea-wrap {
                flex-grow: 1;
                max-height: 25rem;
            }
            textarea.input-field {
                height: 100%;
                resize: vertical;
            }
            .input-field-line {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba($cl-white, .5);
                transform: scaleX(0);
                transform-origin: left;
                transition: transform $dur-default ease;
            }
        }
        &-form-success {
            grid-column: 2/3;
            grid-row: 1/2;
            padding-left: 6rem;
            padding-bottom: 4rem;
            color: rgba($cl-white, 1);
            transition: all $dur-default ease;
            opacity: 1;
            transform: none;
            &.hidden {
                opacity: 0;
                transform: translateY(3.2rem);
            }
        }
        &-submit {
            display: grid;
            width: 25rem;
            appearance: none;
            -webkit-appearance: none;
            border: none;
            outline: none;
            background-color: rgba($cl-white, 1);
            padding: 4rem;
            text-align: left;
            clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
            transition: all $dur-default ease;
            transition-delay: 0s;
            cursor: pointer;
            :hover {
                .popup__main-submit-inner-txt {
                    color: rgba($cl-orange, 1);
                }
            }
            &-aspect {
                grid-column: 1/2;
                grid-row: 1/2;
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                pointer-events: none;
            }
            &-inner {
                grid-column: 1/2;
                grid-row: 1/2;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: start;
                .heading {
                    color: rgba($cl-txt,1);
                    transition: color $dur-default ease;
                }
                .icon {
                    color: rgba($cl-orange, 1);
                }

                transition: all $dur-default ease;
                &-success {
                    padding: 4rem;
                    margin: -4rem;
                    height: calc(100% + 8rem);
                    pointer-events: none;
                    opacity: 0;
                    transform: translateY(2.4rem);
                }
            }
            &.on-complete {
                pointer-events: none;
                .popup__main-submit-inner {
                    &-default {
                        pointer-events: none;
                        opacity: 0;
                        transform: translateY(2.4rem);
                    }
                    &-success {
                        pointer-events: auto;
                        opacity: 1;
                        transform: translateY(0rem);
                    }
                }
            }
        }
    }
    &__close {
        padding: 1.4rem;
        color: rgba($cl-white, .5);
        position: absolute;
        top: 0;
        right: 0;
        transition: color $dur-default ease;
        &:hover {
            color: rgba($cl-white, 1);
        }
    }
}

@include md {
    .popup {
        &__main {
            max-width: calc(100% - 8rem);
            margin-top: 6rem;
            margin-bottom: 6rem;
            width: 100%;
            padding: 6rem;
            padding-bottom: 0;
            &-form {
                grid-template-columns: 4.8fr 5.2fr;
            }
            &-head {
                grid-column: 1/2;
                grid-row: 1/2;
                padding: 3.2rem;
                margin-top: -12rem;
                width: 33rem;
                min-height: 33rem;
                &-title {
                    margin-left: 0;
                    margin-bottom: 2.4rem;
                    font-size: 3.2rem;
                }
                .txt-18 {
                    font-size: 1.8rem;
                }
            }
            &-contact {
                grid-column: 1/2;
                grid-row: 3/4;
                padding-bottom: 4rem;
                &-link {
                    font-weight: 500;
                }
            }
            &-form-inner{
                grid-column: 1/3;
                grid-row: 2/3;
                .input-wrap {
                    margin-left: 0rem;
                    width: 100%;
                }
                .input-field {
                    &::placeholder {
                        color: rgba($cl-white, .8);
                    }
                }
            }
            &-form-success {
                grid-column: 1/3;
                grid-row: 2/3;
                padding-left: 0;
                padding-top: 5rem;
                padding-bottom: 5rem;
            }
            &-submit {
                grid-column: 2/3;
                grid-row: 3/4;
                justify-self: end;
                width: 20rem;
                padding: 3.2rem;
                &-inner {
                    &-success {
                        padding: 3.2rem;
                        margin: -3.2rem;
                        height: calc(100% + 6.4rem);
                    }
                }

            }
        }
        &__close {
            padding: 1.2rem;
            color: rgba($cl-white, 1.0);
            .icon {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }
}

@include sm {
    .popup {
        height: 100svh;
        &__main {
            max-width: calc(100% - 8rem);
            margin-top: 6rem;
            margin-bottom: 0rem;
            padding: 2.4rem;
            padding-top: 0rem;
            padding-bottom: 2.2rem;
            &-form {
                grid-template-columns: 1fr;
            }
            &-head {
                grid-column: 1/2;
                grid-row: 1/2;
                margin-top: -6rem;
                margin: -4.8rem 0 1.2rem;
                min-height: auto;
                width: auto;
                padding: 3rem 3.2rem 2.8rem 2.4rem;
                &-title {
                    margin-bottom: .8rem;
                    font-size: 2.4rem;
                }
                .txt-18 {
                    font-size: 1.4rem;
                }
            }
            &-contact {
                grid-column: 1/2;
                grid-row: 4/5;
                align-items: center;
                text-align: center;
                padding-bottom: 0;
                &-label {
                    font-size: 1.4rem;
                }
                &-link {
                    font-size: 1.6rem;
                }
            }
            &-form-inner{
                grid-column: 1/2;
                grid-row: 2/3;
                margin-bottom: 2.4rem;
                .input-field {
                    padding-top: 1.6rem;
                    padding-bottom: 1.6rem;
                }
                .textarea-wrap {
                    max-height: 20rem;
                }
            }
            &-form-success {
                grid-column: 1/2;
                grid-row: 2/3;
                padding-left: 0;
                padding-top: 4rem;
                padding-bottom: 4rem;
                &-txt {
                    font-size: 1.6rem;
                }
            }
            &-submit {
                grid-column: 1/2;
                grid-row: 3/4;
                justify-self: center;
                width: 100%;
                padding: 1.8rem 2.8rem;
                margin-bottom: 1.2rem;
                &-aspect {
                    display: none;
                }
                &-inner {
                    flex-direction: row;
                    align-items: center;
                    column-gap: 3.2rem;
                    .arr-wrap,
                    .icon {
                        @include size(1.6rem);
                    }
                    &-success {
                        padding: 1.6rem 3.2rem;
                        margin: -1.6rem -3.2rem;
                        height: calc(100% + 3.2rem);
                    }
                }
            }
        }
        &__close {
            padding: 1.2rem;
            z-index: 3;
            color: black;
            margin: {
                top: -4.8rem;
                right: 2.4rem;
            }
            .icon {
                @include size(2.4rem);
            }
        }
    }
}
@include sm {
    .popup {
        &__main {
            max-width: calc(100% - 4rem);
            &-head {
                &-title {
                    font-size: 2.1rem;
                }
            }
        }
    }
}